import { action, makeObservable, observable } from "mobx";

export class TokenStore {

    token?: string | null = null;
    user?: any | null = null;
    token_expiry: Date | null = null;

    constructor() {
        makeObservable(this, {
            token: observable,
            user: observable,
            setToken: action,
            setUser: action,
            logout: action,
        })
        this.getToken();
        this.calculateExpiry();
        this.getUser();
    }
    /***********************************************************************FUNCTIONS */
    public getToken() {
        if (!this.token) {
            let _t = localStorage.getItem('sh.token');
            if (_t) {
                this.token = _t;
                action(() => this.token = _t);
            }
            this.calculateExpiry();
        }
        return this.token;
    }
    public getUser() {
        if (!this.user) {
            let _u = localStorage.getItem('sh.user');
            if (_u) {
                this.user = JSON.parse(_u);
                action(() => this.user = JSON.parse(_u!));
            }
        }
        return this.user;
    }
    public calculateExpiry() {
        let _e = localStorage.getItem('sh.token_expiry');
        if (_e) {
            this.token_expiry = new Date(Number(_e));
        } else if (this.token) {
            this.token_expiry = new Date(Date.now() + 24 * 60 * 60 * 1000);
        }
    }
    public validToken(): boolean {
        return (this.token_expiry != null && this.token_expiry.getTime() > Date.now());
        // return !!this.token;
    }
    /***********************************************************************ACTIONS */
    setToken(t: string | null, ttl: string = '1w') {
        this.token = t;
        if (t) {
            let _e = Date.now();
            if (ttl.endsWith('w')) { // week
                _e += Number(ttl.replace("w", '')) * 7 * 24 * 60 * 60 * 1000
            } else if (ttl.endsWith('d')) { // day
                _e += Number(ttl.replace("d", '')) * 24 * 60 * 60 * 1000
            } else if (ttl.endsWith('M')) { // month
                _e += Number(ttl.replace("M", '')) * 31 * 24 * 60 * 60 * 1000
            } else if (ttl.endsWith('m')) { // minute
                _e += Number(ttl.replace("m", '')) * 60 * 1000
            } else if (ttl.endsWith('s')) { // second
                _e += Number(ttl.replace("s", '')) * 1000
            }
            this.token_expiry = new Date(_e);
            localStorage.setItem('sh.token_expiry', _e.toString());
        } else this.token_expiry = null;
        if (this.token) localStorage.setItem('sh.token', this.token);
        else {
            localStorage.removeItem('sh.token');
            localStorage.removeItem('sh.token_expiry');
        }
    }
    setUser(u: any) {
        this.user = u;
        if (this.user) localStorage.setItem('sh.user', JSON.stringify(this.user));
        else localStorage.removeItem('sh.user');
    }
    public logout() {
        this.setToken(null);
        this.setUser(null);
    }
}

export default new TokenStore();