
export function isNotEmpty(array: any) {
    if (array && Array.isArray(array) && array.length > 0) {
        return true;
    }
    return false;
}

export function isEmpty(array: any) {
    if (!array || (Array.isArray(array) && array.length == 0)) {
        return true;
    }
    return false;
}
export function isNotBlank(value: string): boolean {
    return (value != null && trim(value) != '');
}

export function isBlank(value: string): boolean {
    return (!value || trim(value) == '');
}

export function trim(value: string) {
    if (value && typeof value === 'string') {
        return value.trim();
    }
    return value;
}

export function toNumber(value: any) {
    let result = Number(value);
    if (isNaN(result)) { return 0; }
    return result;
}

export function doubleDecimal(value: any) {
    return toNumber(value.toFixed(2));
}

export function equalsIgnoreCase(str1: string | null | undefined, str2: string | null | undefined) {
    if (str1 == str2) {
        return true;
    } else if (typeof str1 == 'string' && typeof str2 == 'string') {
        return tolowercase(str1) === tolowercase(str2);
    } else { return false; }
}

export function tolowercase(value: any) {
    if (value && typeof value == 'string') {
        return value.toLowerCase();
    }
    return value;
}

export function touppercase(value: any) {
    if (value && typeof value == 'string') {
        return value.toUpperCase();
    }
    return value;
}

export function logError(error: any) {
    if (error && error.response) {
        // Request made and server responded
        console.error(JSON.stringify(error.response.data));
        console.error(error.response.status);
        console.error(error.response.headers);
    } else if (error && error.request) {
        // The request was made but no response was received
        console.error(error.request);
    } else if (error && typeof error === 'object') {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', error.message);
    } else {
        console.error('Error', error);
    }
}

export const isValidEmail = async (email: any) => {
    return /\S+@\S+\.\S+/.test(email);
}

export const isValidMobile = async (mobile: any) => {
    return mobile.match('[0-9]{10}');
}